
import Vue from "vue";
import Api from "@/lib/api";
import { Rq, Table } from "@/lib/amdt";
import { objectCompare } from "@/lib/util";
import VForm from "@/lib/types/v-form";
import IUsuarioEmpresa from "@/lib/interfaces/usuario-empresa";
import { required, maxLength } from "@/lib/validations";
import IUsuarioEmpresaExtraPermission from "@/lib/interfaces/usuario-empresa-extra-permission";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import IPermission from "@/lib/interfaces/permission";
import IUser from "@/lib/interfaces/user";
import ABtnGoBack from "@/components/elements/a-btn-go-back.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";

// ITabItem formato dos dados da tabela
interface ITableItem {
  id: number;
  permissaoNome: string;
  usuarioEmpresaExtraPermission: IUsuarioEmpresaExtraPermission;
}

export default Vue.extend({
  name: "UsuarioEmpresaExtraPermissions",

  components: { vAlertMessage, ABtnGoBack, ASwitch, ABtn, ADataTable, ACard },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",
      teste: "",

      search: "",

      usuarioEmpresa: {} as IUsuarioEmpresa,
      user: {} as IUser,
      permissions: new Array<IPermission>(),

      usuarioEmpresaExtraPermissions:
        new Array<IUsuarioEmpresaExtraPermission>(),

      updateUsuarioEmpresaExtraPermissions: [] as Array<ITableItem>,

      accessLevels: [
        "mdi-cancel",
        "mdi-eye-outline",
        "mdi-plus-thick",
        "mdi-pencil-outline",
        "mdi-trash-can-outline",
      ],

      headers: [
        { text: "MÓDULO", value: "permissaoNome" },
        { text: "NÍVEL DE ACESSO", value: "accessLevel", sortable: false },
        { text: "Status", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    cId(): string {
      return this.$route.params.id;
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },

    cBtnSaveDisabled(): boolean {
      return this.cUpdateData.length === 0 || !this.valid;
    },

    cUpdateData(): Array<Record<string, string>> {
      return this.updateUsuarioEmpresaExtraPermissions
        .map((item) => item.usuarioEmpresaExtraPermission)
        .filter(
          (usuarioEmpresaExtraPermission) =>
            !this.fnSkipRow(usuarioEmpresaExtraPermission)
        )
        .filter(
          (usuarioEmpresaExtraPermission) =>
            !this.usuarioEmpresaExtraPermissions.some((item) =>
              objectCompare(
                item as unknown as Record<string, unknown>,
                usuarioEmpresaExtraPermission as unknown as Record<
                  string,
                  unknown
                >
              )
            )
        ) as unknown as Array<Record<string, string>>;
    },
  },

  mounted() {
    Promise.allSettled([
      this.getUsuarioEmpresaById(),
      this.getUsuarioEmpresaExtraPermissions(),
      this.getPermissions(),
    ]).then(() => {
      this.getUsuarioById().then();
      this.buildTable().then();
    });
  },

  methods: {
    maxLength,
    required,

    fnSkipRow(pp: IUsuarioEmpresaExtraPermission): boolean {
      return (
        pp.id === "default" &&
        pp.leitura === "0" &&
        pp.edicao === "0" &&
        pp.escrita === "0" &&
        pp.remocao === "0" &&
        pp.desativado === "1"
      );
    },

    setPermission(item: ITableItem, values: Array<string>) {
      item.usuarioEmpresaExtraPermission.leitura = values.includes("leitura")
        ? "1"
        : "0";
      item.usuarioEmpresaExtraPermission.edicao = values.includes("edicao")
        ? "1"
        : "0";
      item.usuarioEmpresaExtraPermission.escrita = values.includes("escrita")
        ? "1"
        : "0";
      item.usuarioEmpresaExtraPermission.remocao = values.includes("remocao")
        ? "1"
        : "0";
    },

    getPermission(item: ITableItem) {
      const values = new Array<string>();

      if (item.usuarioEmpresaExtraPermission.leitura === "1")
        values.push("leitura");
      if (item.usuarioEmpresaExtraPermission.edicao === "1")
        values.push("edicao");
      if (item.usuarioEmpresaExtraPermission.escrita === "1")
        values.push("escrita");
      if (item.usuarioEmpresaExtraPermission.remocao === "1")
        values.push("remocao");

      return values;
    },

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("usuario_empresa_permissao_extra.write");
        const tblUsuarioEmpresaPermissaoExtra = rq.addTable(
          new Table("usuario_empresa_permissao_extra")
        );

        tblUsuarioEmpresaPermissaoExtra.addCols(
          ...Object.keys(this.cUpdateData[0])
        );
        tblUsuarioEmpresaPermissaoExtra.addRowsFromObject(...this.cUpdateData);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        } else {
          await this.getUsuarioEmpresaExtraPermissions();
          this.buildTable();
          this.ret_msg = "Permissões atualizadas";
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getUsuarioEmpresaById() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("usuario_empresa.read", {
          id: this.cId,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const usuariosEmpresas =
          rsp.getTable("usuario_empresa")?.getRowsObject() ?? [];
        if (usuariosEmpresas.length > 0) {
          this.usuarioEmpresa = { ...usuariosEmpresas[0] };
        } else {
          this.ret_id = -1;
          this.ret_msg = "Usuário-empresa não encontrado";
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getUsuarioById() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.read", {
          id: this.usuarioEmpresa.id_usuario,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const usuarios = rsp.getTable("usuario")?.getRowsObject() ?? [];
        if (usuarios.length > 0) {
          this.user = { ...usuarios[0] };
        } else {
          this.ret_id = -1;
          this.ret_msg = "Usuário não encontrado";
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getUsuarioEmpresaExtraPermissions() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("usuario_empresa_permissao_extra.read", {
          idUsuarioEmpresa: this.cId,
          itemsPerPage: "-1",
          page: "1",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.usuarioEmpresaExtraPermissions =
          rsp.getTable("usuario_empresa_permissao_extra")?.getRowsObject() ??
          [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPermissions() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("permissao.read", {
          itemsPerPage: "-1",
          page: "1",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.permissions = rsp.getTable("permissao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async buildTable() {
      this.updateUsuarioEmpresaExtraPermissions = this.permissions.map(
        (permission, index) => {
          let obj = {
            id: index,
            usuarioEmpresaExtraPermission: {
              id: "default",
              id_usuario_empresa: this.cId,
              id_permissao: permission.id,
              leitura: "0",
              edicao: "0",
              escrita: "0",
              remocao: "0",
              desativado: "1",
            },
            permissaoNome: permission.descricao,
          } as ITableItem;

          const usuarioEmpresaExtraPermission =
            this.usuarioEmpresaExtraPermissions.find(
              (usuarioEmpresaExtraPermission) =>
                usuarioEmpresaExtraPermission.id_usuario_empresa === this.cId &&
                usuarioEmpresaExtraPermission.id_permissao === permission.id
            );

          if (usuarioEmpresaExtraPermission)
            obj.usuarioEmpresaExtraPermission = {
              ...usuarioEmpresaExtraPermission,
            };

          return obj;
        }
      );
    },
  },
});
